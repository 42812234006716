import React, { useState } from 'react';
import ReactPlayer from 'react-player'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'

const DURATION_WIDTH = 200

const Player = (props) => {
  const {isPlaying, src, onPlay, onPause, title, idx} = props;
  const [duration, setDuration] = useState(null);
  const [played, setPlayed] = useState(0);

  const classes = isPlaying ? "bg-white rounded-md" : ""

  const calcWidth = () => {
    const width = DURATION_WIDTH * played;
    return `${width}px`;
  }

  return (
    <div className={`${classes} px-2 py-2`}>
      <div className="flex items-center">
        <p className="text-lg font-bold">{ title }</p>
        <div className="pl-4  cursor-pointer">
          <FontAwesomeIcon icon={faPlay} onClick={() => onPlay(idx)}/>
        </div>
        { isPlaying &&
          <div className="pl-2  cursor-pointer">
            <FontAwesomeIcon icon={faPause} onClick={() => onPause()}/>
          </div>
        } 
      </div>
      <ReactPlayer onProgress={e => setPlayed(e.played)} onDuration={e => setDuration(e)} preload="none" playing={isPlaying} height="12px" width="200px" controls={false} url={src} />
      {isPlaying &&
        <div className="mb-6" style={{height: "12px", borderRadius: "6px", width: `${DURATION_WIDTH}px`, border: "1px solid black", position: "relative"}}>
          <div style={{borderRadius: "6px", position: "absolute", height: "100%", width: calcWidth(), background: "red"}}></div>
        </div>
      }
    </div>
  )
}

export default Player;
