import React, { useState } from "react";
import Player from "./Player";
import Container from "./Container";

const Album = (props) => {
  const [indexPlaying, setIndexPlaying] = useState(null);
  const { reverse, title, desc } = props;

  const onPlay = (idx) => {
    setIndexPlaying(idx);
  };

  const onPause = () => {
    setIndexPlaying(null);
  };

  const Tracks = () => {
    return (
      <div className={reverse && "pl-8"}>
        {props.tracks.map((track, idx) => {
          return (
            <Player
              title={`${idx + 1}. ${track.title}`}
              isPlaying={indexPlaying === idx}
              src={track.src}
              onPlay={() => onPlay(idx)}
              onPause={onPause}
              idx={idx}
            />
          );
        })}
      </div>
    );
  };

  if (reverse) {
    return (
      <div>
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 py-12">
            <div className="px-8 order-2 md:order-1">
              <h2 className="text-2xl pb-8">{title}</h2>
              <Tracks />
              <button className="mt-12 small-button">Download</button>
              <p className="mt-6 text-lg">{desc}</p>
            </div>
            <img
              className="px-8 order-1 md:order-2"
              src={props.cover}
              width="100%"
            />
          </div>
        </Container>
      </div>
    );
  } else {
    return (
      <div>
        <Container>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3 py-12">
            <img className="px-8" src={props.cover} width="100%" />
            <div className="px-8 pb-8">
              <h2 className="text-2xl pb-8">{title}</h2>
              <Tracks />
              <button className="mt-12 small-button">Download</button>
              <p className="mt-6 text-lg">{desc}</p>
            </div>
          </div>
        </Container>
      </div>
    );
  }
};

export default Album;
