const Card = (props) => {
  const { title, text, smallText } = props;
  return (
    <div className="flex flex-col min-h-full max-w-sm rounded-md overflow-hidden shadow-lg border-4 border-off text-gray-700 px-6 py-4 text-center">
      <h3 className="text-3xl">{title}</h3>
      <p className="pt-4 pb-2 text-lg">{text}</p>
      <p className="mt-auto pt-4 pb-2 text-sm italic">{smallText}</p>
    </div>
  );
};

export default Card;
