import React, { useState } from "react";
import logo from "./logo.png";
import "./App.css";
import Album from "./components/Album";
import Container from "./components/Container";
import Card from "./components/Card";

import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpotify,
  faBandcamp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const CD_TRACKS = [
  {
    title: "Cnidarian Depth",
    src: "/albums/cnidarianDepth/CnidarianDepth.mp3",
  },
  {
    title: "Cruis Ship Pt 1",
    src: "/albums/cnidarianDepth/CruiseShipPt.1.mp3",
  },
  { title: "Sound Wizard", src: "/albums/cnidarianDepth/SoundWizard.mp3" },
  { title: "Call Waiting", src: "/albums/cnidarianDepth/CallWaiting.mp3" },
  { title: "Dino", src: "/albums/cnidarianDepth/Dino.mp3" },
  {
    title: "Gma Luvrr (Bonus Track)",
    src: "/albums/cnidarianDepth/GmaLuvrr.mp3",
  },
];

const DEMO_TRACKS = [
  { title: "KWATSA", src: "/albums/demo/KWATSA.mp3" },
  { title: "China Shop Bull", src: "/albums/demo/china-shop-bull.mp3" },
  { title: "Frog Song", src: "/albums/demo/frog-song.mp3" },
  { title: "Hoax!", src: "/albums/demo/hoax.mp3" },
  { title: "Dearly Departed", src: "/albums/demo/dearly-departed.mp3" },
  { title: "Vampyr", src: "/albums/demo/vampyr.mp3" },
  { title: "Good Morning", src: "/albums/demo/good-morning.mp3" },
  { title: "Shoegazer", src: "/albums/demo/shoegazer.mp3" },
  { title: "Crystal Ball", src: "/albums/demo/crystal-ball.mp3" },
];

function App() {
  const [indexPlaying, setIndexPlaying] = useState(null);

  const onPlay = (idx) => {
    setIndexPlaying(idx);
  };

  return (
    <div className="App">
      <header className="App-header flex flex-col items-center py-16">
        <img src={logo} className="App-logo" alt="logo" width="250px" />
        <div className="Social-links flex">
          <div className="text-4xl mx-2 spotify">
            <a
              href="https://open.spotify.com/artist/79jaeb63rS6wBx1V0CdRet?si=wErmdoO3TzGH_CCKyxMbMA"
              target="_blank"
            >
              <FontAwesomeIcon icon={faSpotify} />
            </a>
          </div>
          <div className="text-4xl mx-2 bandcamp">
            <a href="https://frankfrancionemusic.bandcamp.com/" target="_blank">
              <FontAwesomeIcon icon={faBandcamp} />
            </a>
          </div>
          {/* <div className="text-4xl mx-2 facebook">
            <a href="https://frankfrancionemusic.bandcamp.com/" target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </div> */}
          <div className="text-4xl mx-2 instagram">
            <a href="https://www.instagram.com/franklok2/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </header>
      <div className="divider"></div>
      <div className="pt-24">
        <h2 className="text-8xl mb-16 text-center text-header fun-font">
          Releases
        </h2>

        <div
          className="mx-4"
          style={{
            background:
              "linear-gradient(90deg, rgba(222,180,65,1) 0%, rgba(192,71,113,1) 100%)",
          }}
        >
          <h2 className="text-8xl mb-12 pt-12 text-center text-header fun-font">
            Frank Francione
          </h2>
          <Album
            title="Iditarod In The Sahara"
            cover={"/albums/iits/a2195798099_16.jpeg"}
            tracks={[
              {
                title: "Iditarod In The Sahara",
                src: "/albums/iits/Iditarod-in-the-Sahara.mp3",
              },
            ]}
          />
          <Album
            title="Cnidarian Depth"
            desc="Jams Under The Sea"
            cover={"/albums/cnidarianDepth/cover.jpg"}
            tracks={CD_TRACKS}
            reverse={true}
          />
        </div>

        <div className="bg-off mx-4">
          <h2 className="gothic text-6xl mb-12 pt-12  text-center text-black">
            New Architecture
          </h2>
          <Album
            title="Demo"
            desc="Demo For New Architecture"
            cover={"/albums/demo/demo.jpg"}
            tracks={DEMO_TRACKS}
          />
        </div>

        <div className="mx-4" style={{ background: "#b269e6" }}>
          <h2 className="warlock text-6xl mb-12 text-center pt-12">
            Unnamed Warlock
          </h2>
          <h3 className="text-center text-4xl pb-12 warlock">Coming Soon...</h3>
        </div>
      </div>
      <div className="divider mt-16"></div>
      <div>
        <Container>
          <h2 className="text-8xl text-center pt-32 text-header fun-font">
            My Skills
          </h2>
          <div className="pt-8 flex flex-col md:flex-row justify-between items-center items-stretch">
            <div className="mx-2 my-2 flex flex-col items-stretch">
              <Card
                title="Musician"
                smallText="I currently front my band New Artchitecture."
                text="I have 15+ years of experience as a musician playing guitar/bass, drums, vocals, keyboard, and harmonica."
              />
            </div>
            <div className="mx-2 my-2 flex flex-col items-stretch">
              <Card
                title="Production"
                text="I have produced, mixed, and mastered multiple albums and EPs ranging from electronic, to punk, to folk, and even video game soundtracks."
                smallText="Contact for mastering rates."
              />
            </div>
            <div className="mx-2 my-2 flex flex-col items-stretch">
              <Card
                title="DJ"
                text="I spin electronic music, mainly drum and bass, jungle, and downtempo. I can work in any style of music however, and can adapt my set to fit your needs."
                smallText="Contact For Booking."
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="divider mt-16"></div>
      <div className="px-3">
        <h2 className="text-8xl text-center pt-32 text-header fun-font">
          Contact
        </h2>
        <div className="mt-8 pb-12 flex flex-col max-w-3xl mx-auto">
          <input
            className="border my-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            type="text"
            placeholder="Name"
          />
          <input
            className="border my-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            type="text"
            placeholder="Email"
          />
          <textarea className="border my-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            Message
          </textarea>
          <button>Submit</button>
        </div>
      </div>
      <div className="text-center">
        <p>Copyright Frank Francione 2022</p>
      </div>
    </div>
  );
}

export default App;
